export const PowerBtn = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="power-off"
      className="prefix__svg-inline--fa prefix__fa-power-off prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill={props.fill}
        d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
      />
    </svg>
  )

}

export const Github = (props) => {
    return (
        <svg
          aria-hidden="true"
          data-prefix="fab"
          data-icon="github"
          className="prefix__svg-inline--fa prefix__fa-github prefix__fa-w-16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
          {...props}
        >
          <path
            fill={props.fill}
            d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
          />
        </svg>
      )
}






export const YinYang = (props) => {
    return (
        <svg
          aria-hidden="true"
          data-prefix="fas"
          data-icon="yin-yang"
          className="prefix__svg-inline--fa prefix__fa-yin-yang prefix__fa-w-16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
          {...props}
        >
          <path
            fill={props.fill}
            d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 376c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm0-128c-53.02 0-96 42.98-96 96s42.98 96 96 96c-106.04 0-192-85.96-192-192S141.96 64 248 64c53.02 0 96 42.98 96 96s-42.98 96-96 96zm0-128c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32z"
          />
        </svg>
      )
}

export const Anchor = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="anchor"
      className="prefix__svg-inline--fa prefix__fa-anchor prefix__fa-w-18"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      {...props}
    >
      <path
        fill={props.fill}
        d="M12.971 352h32.394C67.172 454.735 181.944 512 288 512c106.229 0 220.853-57.38 242.635-160h32.394c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h35.146c-20.29 54.317-84.963 86.588-144.117 94.015V256h52c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-52v-5.47c37.281-13.178 63.995-48.725 64-90.518C384.005 43.772 341.605.738 289.37.01 235.723-.739 192 42.525 192 96c0 41.798 26.716 77.35 64 90.53V192h-52c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v190.015c-58.936-7.399-123.82-39.679-144.117-94.015h35.146c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0L4.485 331.515C-3.074 339.074 2.28 352 12.971 352zM288 64c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"
      />
    </svg>
  )
}



export const Link = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="link"
      className="prefix__svg-inline--fa prefix__fa-link prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}

    >
      <path
        fill={props.fill}

        d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 01-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0120.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0020.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 00-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
      />
    </svg>
  )
}



export const Design = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="palette"
      className="prefix__svg-inline--fa prefix__fa-palette prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill={props.fill}
        d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
      />
    </svg>
  )

}

export const Develope = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="laptop-code"
      className="prefix__svg-inline--fa prefix__fa-laptop-code prefix__fa-w-20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      {...props}
    >
      <path
        fill={props.fill}
        d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z"
      />
    </svg>
  )

}


export const LinkedIn = (props) => (
  <svg  xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"Linkedin"}</title>
    <path
      d="M20.972 33.553H25v-6.42c0-1.694.32-3.334 2.42-3.334 2.07 0 2.096 1.936 2.096 3.441v6.313h4.034v-7.119c0-3.496-.755-6.183-4.84-6.183-1.963 0-3.279 1.076-3.817 2.096h-.055v-1.774h-3.867v12.98ZM16.423 14.12a2.339 2.339 0 1 0 0 4.68 2.34 2.34 0 0 0 0-4.68Zm-2.02 19.433h4.038v-12.98h-4.038v12.98ZM9.766 40A1.766 1.766 0 0 1 8 38.234V9.766C8 8.791 8.79 8 9.766 8h28.468C39.209 8 40 8.79 40 9.766v28.468c0 .975-.79 1.766-1.766 1.766H9.766Z"
      fill={props.fill}

    />
  </svg>
)
export const Resume = (props) => (
  <svg  xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"Linkedin"}</title>
    <path
      d="M38.914,0H6.5v60h47V14.586L38.914,0z M34.5,31c0,0.553-0.448,1-1,1h-10c-0.552,0-1-0.447-1-1s0.448-1,1-1h10
	C34.052,30,34.5,30.447,34.5,31z M16.262,23.479l0.32-0.741c0.291-0.674,0.769-1.207,1.382-1.541l2.204-1.087
	c-0.969-0.622-1.667-1.558-2.083-2.793c-0.684-0.417-1.115-1.158-1.115-1.984v-0.667c0-0.668,0.289-1.293,0.778-1.73
	c0.096-0.613,0.254-1.189,0.471-1.719c0.102-0.247,0.188-0.432,0.257-0.582c0.046-0.1,0.104-0.224,0.112-0.259
	c0.003-0.032-0.002-0.153-0.086-0.573c-0.065-0.327-0.108-0.627-0.138-0.884c-0.037-0.322,0.065-0.645,0.279-0.885
	c0.448-0.5,1.321-0.477,1.739,0.056c0.076,0.097,0.231,0.255,0.534,0.459c0.512,0.346,1.208,0.502,1.937,0.438
	c0.147-0.012,0.297-0.018,0.451-0.018c3.896,0,5.183,2.053,5.54,3.951c0.5,0.438,0.794,1.068,0.794,1.745v0.667
	c0,0.826-0.431,1.567-1.115,1.984c-0.418,1.24-1.12,2.178-2.095,2.801l2.122,1.083c0.632,0.317,1.135,0.848,1.447,1.531l0.344,0.752
	l-0.674,0.479C27.792,25.296,25.592,26,23.302,26c-2.298,0-4.505-0.709-6.384-2.052L16.262,23.479z M19.5,31
	c0,0.26-0.11,0.52-0.29,0.71C19.02,31.89,18.77,32,18.5,32s-0.53-0.11-0.71-0.3c-0.18-0.181-0.29-0.44-0.29-0.7
	c0-0.261,0.11-0.521,0.29-0.71c0.36-0.36,1.05-0.37,1.42,0C19.39,30.479,19.5,30.739,19.5,31z M17.5,36c0-0.553,0.448-1,1-1h17
	c0.552,0,1,0.447,1,1s-0.448,1-1,1h-17C17.948,37,17.5,36.553,17.5,36z M22.686,47.766c-0.031,0.918-1.045,1.262-1.707,0.707
	c-0.935-0.784-1.871-1.567-2.806-2.351c-0.99-0.83,0.433-2.237,1.414-1.415c0.414,0.347,0.828,0.693,1.241,1.04
	c0.268-2.261,1.005-4.441,3.196-5.582c1.142-0.594,2.154,1.132,1.009,1.727C22.863,43.023,22.759,45.599,22.686,47.766z
	 M37.637,46.259c-0.246-0.337-3.424,1.211-4.333,0.883c-0.713-0.257-1.248-0.585-1.697-1.067c-2.122,1.151-4.536,1.774-6.407-0.259
	c-0.871-0.947,0.539-2.365,1.414-1.414c1.489,1.619,3.34,0.369,4.756-0.5c0.458-0.281,1.106-0.109,1.368,0.359
	c1.034,1.843,3.535-0.242,4.992-0.132c0.773,0.059,1.209,0.542,1.632,1.121C40.126,46.293,38.389,47.289,37.637,46.259z
	 M41.21,36.71C41.02,36.89,40.76,37,40.5,37c-0.26,0-0.52-0.11-0.71-0.29C39.6,36.52,39.5,36.27,39.5,36
	c0-0.271,0.11-0.521,0.29-0.71c0.38-0.37,1.04-0.37,1.42,0c0.18,0.189,0.29,0.449,0.29,0.71C41.5,36.27,41.4,36.52,41.21,36.71z
	 M46.21,36.7C46.02,36.89,45.76,37,45.5,37c-0.26,0-0.52-0.11-0.71-0.29C44.6,36.52,44.5,36.27,44.5,36
	c0-0.271,0.11-0.521,0.29-0.71c0.38-0.37,1.04-0.37,1.42,0c0.18,0.189,0.29,0.449,0.29,0.71C46.5,36.26,46.4,36.51,46.21,36.7z
	 M45.5,32h-7c-0.552,0-1-0.447-1-1s0.448-1,1-1h7c0.552,0,1,0.447,1,1S46.052,32,45.5,32z M37.5,16V2l14,14H37.5z"
      fill={props.fill}

    />
  </svg>
)
